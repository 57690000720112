import { LoadContent } from "leeloo-react/component/display/LoadContent";
import { useCallbackOutsideRef } from "leeloo-react/component/hook/useCallbackOutsideRef";
import { DataSourceType } from "leeloo-react/component/hook/useDataSource";
import React, { useState } from "react";
import { ReferentielDto } from "../../model/api-dto";

type Props = {
    dsReferentiel: DataSourceType
}

/**
 * ReferentielHeader
 * Représente les données du header du référentiel en cours de modif/visualisé
 */
export const ReferentielHeader = (props: Props) => {
    const [isShowMenuRef, setIsShowMenuRef] = useState(false);
    const refMenuReferentiel = useCallbackOutsideRef(() => setIsShowMenuRef(false));
    
    const referentiel = props.dsReferentiel.oneData as ReferentielDto;
    const classIsShowMenuRef = (isShowMenuRef) ? " show":"";
    return (
        <div className="repository-head">
            <div className="resume">
                <LoadContent isLoad={!props.dsReferentiel.isReady}>
                    <h1 className="h1">
                        <small>Référentiel</small>
                        {referentiel?.libelle}
                        <div ref={refMenuReferentiel} className="dropdown">
                            <button onClick={() => setIsShowMenuRef(!isShowMenuRef)} className="btn btn-sm btn-light-primary">
                                <i className="uil uil-ellipsis-h"></i>
                            </button>
                            <ul className={"dropdown-menu"+classIsShowMenuRef} aria-labelledby="actions-menu-1">
                                <li><button className="dropdown-item">Modifier</button></li>
                                <li><button className="dropdown-item">Dupliquer</button></li>
                                <li><button className="dropdown-item">Archiver</button></li>
                                <li><button className="dropdown-item text-danger">Supprimer</button></li>
                            </ul>
                        </div>
                    </h1>

                    <ul className="metas">
                        <li>
                            <span className="title">Dernière modification</span>
                            {/* TODO API DERNIERE MODIF - 22/11/2021 - Il y a 18 jours */}
                        </li>
                        {/*<li>
                            <span className="title">Référents 6tm</span>
                            <button >Voir les contacts référents</button>
                        </li> */}
                        {/* TODO API VERSION DISPONIBLE */}
                        {/* <li>
                            <span className="title">Version</span>
                            <div className="dropdown">
                                <button className="dropdown-toggle" title="Versions">V 1.12</button>
                                <ul className="dropdown-menu" aria-labelledby="versions-menu">
                                    <li><button className="dropdown-item active">1.12</button></li>
                                    <li><button className="dropdown-item">1.11</button></li>
                                    <li><button className="dropdown-item">1.10</button></li>
                                </ul>
                            </div>

                        </li> */}
                    </ul>
                </LoadContent>
            </div>

            <div className="score-boxes">
                {/* TODO API ajout niveau */}
                {/* <ScoringRefCard level={1} score={21} text="Mon niveau" />
                <ScoringRefCard level={1} score={21} text="Mon niveau" />
                <ScoringRefCard level={1} score={21} text="Mon niveau" /> */}
            </div>

        </div>
    )
}