import { LoadContent } from "leeloo-react/component/display/LoadContent";
import { ElementEditor } from "leeloo-react/component/elementEditor/ElementEditor";
import { SubmitBtn } from "leeloo-react/component/form/action/SubmitBtn";
import { TextareaField } from "leeloo-react/component/form/field/TextareaField";
import { Form } from "leeloo-react/component/form/Form";
import { useDataSource } from "leeloo-react/component/hook/useDataSource";
import { DataSourceType } from "leeloo-react/component/hook/useDataSource";
import React, { useEffect } from "react";
import { ResourceDataProviderEnum } from "../../enum/ResourceDataProviderEnum";
import { ExigenceDto } from "../../model/api-dto";
import Skeleton from 'react-loading-skeleton'

type Props = {
    exigenceId: number,
    setExigenceId: (e:number) => void,
    dsExigences: DataSourceType
}

/**
 * ExigenceShowContent
 * Affiche le contenu d'une exigence, donne la main en édition ainsi que la création
 */
export const ExigenceShowContent = (props: Props) => {
    
    const dsExigenceEdit = useDataSource<ExigenceDto>({
        resource: ResourceDataProviderEnum.EXIGENCE,
        params: {id: props.exigenceId},
        singleResult: true
    });

    useEffect(() => {
        if (props.exigenceId != 0) {
            dsExigenceEdit.setParams({id: props.exigenceId});
        }
    }, [props.exigenceId]);

    const description = (dsExigenceEdit.oneData?.description) ?? "Pas de description";
    
    const commonParamsElementEditor = {
        id: props.exigenceId,
        resource: ResourceDataProviderEnum.EXIGENCE,
        iconClose: "uil uil-multiply",
        iconValid: "uil uil-check",
        enabledValidOnBlurEdit: true
    }

    return (
        <div className="rule-details">

            {/* MODE AJOUT */}
            {props.exigenceId == 0 ? (
                <Form<ExigenceDto>
                    initialValue={{}}
                    resource={ResourceDataProviderEnum.EXIGENCE}
                    onSubmitEndUpdate={(d, dataComplement) => {
                        props.setExigenceId(dataComplement.newId as number);
                        props.dsExigences.refreshData();
                    }}
                >
                    <h2 className="h2">Nouvelle exigence</h2>
                    <TextareaField<ExigenceDto> label="Libelle" name="libelle" />
                    <TextareaField<ExigenceDto> label="Description" name="description" />
                    <SubmitBtn className="btn btn-primary" />
                </Form>
            ) : (
                <LoadContent 
                    isLoad={dsExigenceEdit.isLoading} 
                    latencyBeforeLoad={0}
                    renderLoader={<>
                        <Skeleton />
                        <br/>
                        <Skeleton count={3} />
                        <br/> 
                        <Skeleton count={2} />
                    </>}
                >
                    {/* MODE SHOW/EDITION A LA VOLEE */}
                    {/* <ExigenceAttributes/> */}
                    <ElementEditor
                        {...commonParamsElementEditor}
                        fieldName="libelle"
                        initialValue={dsExigenceEdit.oneData?.libelle}
                        inputElement={<TextareaField className="h2" />}
                        renderElement={(v) => <h2 className="h2">{v}</h2>}
                        onEndApiUpdate={() => {
                            props.dsExigences.refreshData();
                        }}
                    />

                    <ElementEditor
                        {...commonParamsElementEditor}
                        fieldName="description"
                        initialValue={description}
                        inputElement={<TextareaField />}
                        renderElement={(v) => <p className="description">{v}</p>}
                        onEndApiUpdate={() => {
                            props.dsExigences.refreshData();
                        }}
                    />

                    {/* TODO API Objectif sous forme de liste Array manquante ex ["Objectif 1", "Objectif 2"] */}
                    {/* <h3 className="h3">Objectifs</h3>
                    <ul>
                        <li>
                            Permettre la compréhension des contenus par les utilisateurs dont le navigateur n’appliquera pas les feuilles de styles du site ou dont le mode d’accès n’est pas visuel.
                        </li>
                        <li>
                            Séparer rigoureusement les contenus de la présentation pour améliorer leur interopérabilité.
                        </li>
                        <li>
                            Favoriser le référencement du contenu.
                        </li>
                        <li>
                            Améliorer l’accessibilité des contenus aux personnes handicapées
                        </li>
                    </ul> */}

                    <ul className="metas">
                        {/* TODO API ajout date derniere modif ainsi que l'auteur */}
                        <li>
                            <span className="title">Dernière modification</span>
                            Le 12 Juillet 2021, par Yvan Loichon
                        </li>
                    </ul>
                </LoadContent>
            )}
        </div>
    )
}