import { useDataSource } from "leeloo-react/component/hook/useDataSource";
import { useRouter } from "leeloo-react/component/hook/useRouter";
import React, { useEffect, useState } from "react";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { ExigenceDto, ReferentielDto } from "../../../model/api-dto";
import { ExigenceEmptyContent } from "../../exigence/ExigenceEmptyContent";
import { ExigenceList } from "../../exigence/ExigenceList";
import { ExigenceShowContent } from "../../exigence/ExigenceShowContent";
import { BaseContainer } from "../../layout/BaseContainer";
import { ReferentielHeader } from "../../referentiel/ReferentielHeader";

/**
 * Page d'affichage du référentiel, ses éxigences
 */
export const ReferentielShowPage = () => {
    const {currentParamsRoute} = useRouter();
    const [currentIdExigenceChoose, setCurrentIdExigenceChoose] = useState<number|null>(null);

    const dsReferentiel = useDataSource<ReferentielDto>({
        resource: ResourceDataProviderEnum.REFERENTIEL,
        singleResult: true,
        params: {
            id: currentParamsRoute.id
        }
    });

    const dsExigences = useDataSource<ExigenceDto>({
        resource: ResourceDataProviderEnum.EXIGENCE,
        params: {
            filter: {
                referentielId: currentParamsRoute.id
            },
            pagination: {page: 1, take: 25},
            sort: {field: "ref", order: "asc"}
        }
    });

    return (
        <BaseContainer>
            {/* Partie haute information générale sur le referentiel */}
            <ReferentielHeader dsReferentiel={dsReferentiel} />

            {/* TODO rebrancher apres fix API header */}
            <button className="btn btn-primary" onClick={() => setCurrentIdExigenceChoose(0)}>Ajouter une éxigence</button>
            <div className="two-cols-reader">
                <div className="col-left">
                    {/* Partie listing et filtre exigence */}
                    <ExigenceList dsExigences={dsExigences} onClickExigence={(e) => {setCurrentIdExigenceChoose(e.id!)}} />
                </div>
                
                <div className="col-right">
                    {/* Partie affichage exigence non choisie */}
                    {currentIdExigenceChoose == null && <ExigenceEmptyContent />}

                    {/* Partie affichage d'une exigence choisie */}
                    {currentIdExigenceChoose != null && <ExigenceShowContent dsExigences={dsExigences} exigenceId={currentIdExigenceChoose} setExigenceId={setCurrentIdExigenceChoose} />}
                </div>
            </div>
        </BaseContainer>
    )
}