import React from "react";
import { RouterLink } from "leeloo-react/component/router/RouterLink";
import { RouteEnum } from "../../enum/RouteEnum";
import { RouterMenuLink } from "leeloo-react/component/router/RouterMenuLink";
import { Logo } from "../display/Logo";

type Props = {
    children?: JSX.Element|JSX.Element[]
}

/**
 * Containeur de base de toute les pages
 * TODO implémenter l'intégration structurelle ici
 */
export const BaseContainer = (props: Props) => {
    
    return (<>
        <header>
            <RouterLink className="logo" routeName={RouteEnum.HOME}>
                <Logo />
            </RouterLink>

            <nav aria-label="Navigation principale">
                <RouterMenuLink />
            </nav>

        </header>

        {/* EN ATTENTE MAJ BOOTSTRAP 4 -> 5 SUR LEELOO <nav className="breadcrumb-nav" aria-label="Fil d'ariane">
            <ol className="breadcrumb">
                <li className="breadcrumb-item active"><a href="./">Tableau de bord</a></li>
            </ol>
        </nav> */}


        <main>{props.children}</main>

        <footer>
            © Auditorium / 6tm 2022
        </footer>
    </>);
};
