import { RouteType } from "leeloo-react/model/RouteType";
import { ReferentielShowPage } from "../components/page/referentiel/ReferentielShowPage";
import { ReferentielListPage } from "../components/page/referentiel/ReferentielListPage";
import { HomePage } from "../components/page/HomePage";
import { RouteEnum } from "../enum/RouteEnum";

/**
 * Configuration des routes exprimé ici
 */
export const RouteConfig: RouteType[] = [
    {
        label: "Referentiel",
        routeUrl: RouteEnum.REFERENTIEL_SHOW,
        pageComponent: ReferentielShowPage
    },
    {
        label: "Referentiel",
        routeUrl: RouteEnum.REFERENTIEL,
        pageComponent: ReferentielListPage
    },
    {
        label: "Accueil",
        routeUrl: RouteEnum.HOME, 
        pageComponent: HomePage
    }
]
