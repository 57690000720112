import { useCallbackOutsideRef } from "leeloo-react/component/hook/useCallbackOutsideRef";
import { RouterLink } from "leeloo-react/component/router/RouterLink";
import React, { useState } from "react";
import { RouteEnum } from "../../enum/RouteEnum";
import { ReferentielDto } from "../../model/api-dto";

/**
 * Représente un élément du référentiel actuelle
 */
export const ReferentielCard = (props: {referentiel: ReferentielDto}) => {
    const [open, setOpen] = useState(false);
    const refDiv = useCallbackOutsideRef(() => setOpen(false));
    
    const isShow = (open) ? " show":"";
    return (
        <div className="card card-repository">
            <div className="card-body">

                <h1 className="h1">
                    <small>Référentiel</small>
                    {props.referentiel.libelle}
                </h1>

                <ul className="metas">
                    <li>
                        <span className="title">Dernière modification</span>
                        22/11/2021 - Il y a 18 jours
                    </li>
                    <li>
                        <span className="title">Version actuelle</span>
                        V {props.referentiel.versionMajeure}.{props.referentiel.versionMineure}
                    </li>
                </ul>

                <div className="score-boxes">
                    {/* TODO API ajout niveau */}
                </div>

            </div>
            <div className="card-footer">

                <RouterLink routeName={RouteEnum.REFERENTIEL_SHOW} className="btn btn-primary" params={{id: props.referentiel.id!}} >
                    Consulter
                </RouterLink>
                <button className="btn btn-light-primary">Nouvel audit</button>

                <div ref={refDiv} className="dropdown">
                    <button  onClick={() => setOpen(!open)} className="btn btn-sm btn-light-primary" type="button" aria-expanded="false" title="Actions">
                        <i className="uil uil-ellipsis-h"></i>
                    </button>
                    <ul className={"dropdown-menu dropdown-menu-end"+isShow}>
                        <li><button className="dropdown-item" >Modifier</button></li>
                        <li><button className="dropdown-item" >Dupliquer</button></li>
                        <li><button className="dropdown-item" >Archiver</button></li>
                        <li><button className="dropdown-item text-danger" >Supprimer</button></li>
                    </ul>
                </div>

            </div>
        </div>

    )
}