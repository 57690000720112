import { LoadContent } from "leeloo-react/component/display/LoadContent";
import { Filter } from "leeloo-react/component/filter/Filter";
import { InputField } from "leeloo-react/component/form/field/InputField";
import { DataSourceType } from "leeloo-react/component/hook/useDataSource";
import { List } from "leeloo-react/component/list/List";
import React, { useMemo, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { ExigenceDto } from "../../model/api-dto";
import { ExigenceCard } from "./ExigenceCard";

type Props = {
    dsExigences: DataSourceType,
    onClickExigence: (exigence: ExigenceDto) => void
}

/**
 * ExigenceList
 * Filtre et listing des exigences
 */
export const ExigenceList = (props: Props) => {
    
    const titleSectionChapitre: any = useMemo(() => {
        const data: any = {};
        props.dsExigences.dataList.forEach((v: any) => {
            if (v.section && !data.hasOwnProperty(v.section)) {
                data[v.section] = v.id;
            }
            if (v.chapitre && !data.hasOwnProperty(v.chapitre)) {
                data[v.chapitre] = v.id;
            }
        });
        return data;
    }, [props.dsExigences.dataList]);
console.log(titleSectionChapitre);
    return (<>
        {/* Filtrage des exigences */}
        <div className="mb-4">
            <Filter dataSource={props.dsExigences}>
                {/* TODO API véritable champ de recherche */}
                <InputField typeFilter="like" name="libelle" placeholder="Rechercher …" />
                {/* TODO API tags en format ['tag1', 'tag2'] */}
                {/* <SelectField 
                    name="tags"
                    placeholder="Filtrer par tags" 
                /> */}
                {/* TODO API ajout niveau en tableau ex niveau: [1,2,3] */}
                {/* <SelectCheckRadioField
                    label="Niveau"
                    renderFormCheckContainer={(formCheckComponent) => <div className="form-check form-check-inline">{formCheckComponent}</div>}
                    isMulti
                    optionsList={[
                        {label: "Niveau 1", value: 1},
                        {label: "Niveau 2", value: 2},
                        {label: "Niveau 3", value: 3},
                    ]}
                /> */}
            </Filter>
        </div>

        {/* Listing des exigences */}
        <LoadContent
            isLoad={props.dsExigences.isLoading}
            renderLoader={<Skeleton style={{height: 120}} count={5} />}
        >
            <List<ExigenceDto>
                dataSource={props.dsExigences}
                renderElement={(exigence) => {
                    const render = [];
                    if (titleSectionChapitre.hasOwnProperty(exigence.chapitre) && titleSectionChapitre[exigence.chapitre!] == exigence.id)
                        render.push(<h3 key={"chapitre-"+exigence.id} className="h3">{exigence.chapitre}</h3>);
                    if (titleSectionChapitre.hasOwnProperty(exigence.section) && titleSectionChapitre[exigence.section!] == exigence.id)
                        render.push(<h3 key={"section-"+exigence.id} className="h3">{exigence.section}</h3>);

                    render.push(<ExigenceCard key={"exigence-"+exigence.id} onClick={() => props.onClickExigence(exigence)} exigence={exigence} />);
                    return <div key={exigence.id}>{render}</div>;
                }}
            /> 
        </LoadContent>
    </>)
}