import React from "react";
import { RouterLink } from "leeloo-react/component/router/RouterLink";
import { BaseContainer } from "../layout/BaseContainer";
import { RouteLeelooEnum } from 'leeloo-react/enum/RouteLeelooEnum';

export const HomePage = () => {
    return (
        <BaseContainer>
            <div className="col-md-12">
                {/* Example génération lien sur la route leeloo login */}
                <RouterLink routeName={RouteLeelooEnum.login} />
            </div>
        </BaseContainer>
    );
};
