import React from "react";
import { ExigenceDto } from "../../model/api-dto";
import { ExigenceTag } from "./ExigenceTag";

/**
 * ExigenceAttributes
 */
export const ExigenceAttributes = (props: {exigence: ExigenceDto}) => {

    // TODO API ajout niveau en tableau ex niveau: [1,2,3]
    // TODO API tags en format ['tag1', 'tag2']
    const level = [1,2,3];
    const tags = [
        <ExigenceTag key={1} name="Tag1" />,
        <ExigenceTag key={2} name="Tag2" />,
    ];
    return (
        <div className="attributes">
            {level.map((l) => {
                <span key={l} className={"badge badge-niveau-"+l}>Niveau {l}</span>
            })}
            {tags}
        </div>
    )
}