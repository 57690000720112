import React, { useState } from "react";
import { BaseContainer } from "../../layout/BaseContainer";
import { ReferentielCard } from "../../referentiel/ReferentielCard";
import moment from "moment";
import { ScoringRefCard } from "../../referentiel/ScoringRefCard";
import { ModalForm } from "leeloo-react/component/form/ModalForm";
import { InputField } from "leeloo-react/component/form/field/InputField";
import { TextareaField } from "leeloo-react/component/form/field/TextareaField";
import { List } from "leeloo-react/component/list/List";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { ReferentielDto } from "../../../model/api-dto";
import { LoadContent } from "leeloo-react/component/display/LoadContent";
import { useDataSource } from "leeloo-react/component/hook/useDataSource";
import Skeleton from "react-loading-skeleton";

/**
 * TODO doc here
 */
export const ReferentielListPage = () => {
    const [modalAddShow, setModalAddShow] = useState(false);

    const dsRef = useDataSource<ReferentielDto>({
        resource: ResourceDataProviderEnum.REFERENTIEL,
        params: {pagination: {page: 1, take: 25}}
    })

    return (
        <BaseContainer>
            <div className="d-flex justify-content-end gap-3 mb-4">
                <button onClick={() => setModalAddShow(true)} className="btn btn-primary">Créer un nouveau référentiel</button>
            </div>
            
            <LoadContent
                isLoad={dsRef.isLoading}
                renderLoader={<div className="col"><Skeleton style={{height: 345, width: "50%"}} /></div>}
            >
                <List<ReferentielDto>
                    customClassContainer=" "
                    customClassContent="row row-cols-1 row-cols-md-2 g-4 mb-3"
                    dataSource={dsRef}
                    renderElement={(ref) => {
                        return (
                            <div className="col">
                                <ReferentielCard referentiel={ref} />
                            </div>
                        )
                    }}
                    disabledPagination
                />
            </LoadContent>

            {/* Modale d'ajout */}
            <ModalForm
                title="Créer un nouveau référentiel"
                show={modalAddShow}
                initialValue={{}}
                handleClose={() => setModalAddShow(false)}
            >
                <InputField name="title" label="Titre *" />
                <TextareaField name="contactRef" label="Contacts référents *" />
            </ModalForm>
        </BaseContainer>
    )
}