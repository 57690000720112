import React from "react";
import { ExigenceDto } from "../../model/api-dto";
import { ExigenceAttributes } from "./ExigenceAttributes";

/**
 * ExigenceCard
 */
export const ExigenceCard = (props: {exigence: ExigenceDto, onClick: (id: number) => void}) => {
    
    return (
        <div className="card card-rule" onClick={() => props.onClick(props.exigence.id!)}>
            <div className="card-body">
                <ExigenceAttributes {...props} />
                <div className="title stretched-link">
                    {props.exigence.libelle}
                </div>
            </div>
        </div>
    )
}