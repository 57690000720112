import React from "react";

/**
 * ExigenceEmptyContent
 * Contenu vide d'une exigence
 * Correspond a la non selection d'une exigence dans le ReferentielShow
 */
export const ExigenceEmptyContent = () => {
    
    return (
        <div className="empty-state">
            <svg xmlns="http://www.w3.org/2000/svg" width="62.206" height="62.206"><path data-name="file-search-alt (2)" d="M31.094 55.969H9.328a3.109 3.109 0 0 1-3.109-3.109V9.328a3.109 3.109 0 0 1 3.109-3.109h15.547v9.328a9.328 9.328 0 0 0 9.325 9.328h9.328v3.109a3.11 3.11 0 1 0 6.219 0v-6.405a4.074 4.074 0 0 0-.187-.84v-.28a3.327 3.327 0 0 0-.591-.871L30.317.933a3.327 3.327 0 0 0-.871-.591 1 1 0 0 0-.28 0A2.736 2.736 0 0 0 28.14 0H9.328A9.328 9.328 0 0 0 0 9.328V52.86a9.328 9.328 0 0 0 9.328 9.328h21.766a3.11 3.11 0 0 0 0-6.219Zm0-45.366 8.053 8.053H34.2a3.109 3.109 0 0 1-3.109-3.109Zm-15.547 8.053a3.11 3.11 0 0 0 0 6.219h3.109a3.11 3.11 0 0 0 0-6.219Zm45.739 38.215-3.638-3.607a10.7 10.7 0 0 0-1.679-12.842 10.948 10.948 0 1 0-2.705 17.226l3.607 3.638a3.122 3.122 0 1 0 4.415-4.415Zm-9.857-5.441a4.788 4.788 0 0 1-6.561 0 4.664 4.664 0 0 1-1.337-3.265 4.54 4.54 0 0 1 1.368-3.3 4.646 4.646 0 0 1 7.96 3.3 4.664 4.664 0 0 1-1.43 3.265ZM34.2 31.094H15.547a3.11 3.11 0 0 0 0 6.219H34.2a3.11 3.11 0 0 0 0-6.219ZM27.984 49.75a3.11 3.11 0 1 0 0-6.219H15.547a3.11 3.11 0 0 0 0 6.219Z" fill="#cbd5e1"/></svg>
            <p>
                Sélectionnez un critère pour en afficher le détail.
            </p>
        </div>
    )
}