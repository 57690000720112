import React from 'react';
import { RouteConfig } from "./config/RouteConfig";
import { RouteEnum } from "./enum/RouteEnum";
import { LoginForm } from './components/page/auth/LoginForm';
import { LeelooSpa } from 'leeloo-react/LeelooSpa';
import { JwtAuthProvider } from 'leeloo-react/authProvider/JwtAuthProvider';
import { UniconIconRender } from 'leeloo-react/component/display/iconRender/UniconIconRender';
import { MenuRouteConfig } from './config/MenuRouteConfig';

// STYLE VISUEL
import "./scss/main.scss";
import 'react-loading-skeleton/dist/skeleton.css';
import { ODataProvider } from 'leeloo-react/dataProvider/ODataProvider';

function App() {
    return (
        <LeelooSpa
            dataProvider={ODataProvider}
            authProvider={JwtAuthProvider}
            componentConfig={{iconRender: UniconIconRender}}
            allowDynamicTitleChange
            defaultPrivateRoute={RouteEnum.HOME}
            loginForm={LoginForm}
            apiUrl={process.env.REACT_APP_API_URL!}
            routeConfig={RouteConfig}
            menuConfig={MenuRouteConfig}
        />
    );
}
export default App;
