import React from "react";

type Props = {
    name: string
}

/**
 * ExigenceTag
 */
export const ExigenceTag = (props: Props) => {
    
    return (
        <span className="tag">{props.name}</span>
    )
}