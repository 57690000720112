
import { GroupRouteType } from "leeloo-react/model/GroupRouteType";
import { RouteEnum } from "../enum/RouteEnum";

export const MenuRouteConfig: GroupRouteType[] = [
    {
        label: "Dashboard",
        routes: [
            RouteEnum.HOME
        ]
    },
    {
        label: "Référentiels",
        routes: [
            RouteEnum.REFERENTIEL
        ]
    }
];
