import React from "react";
import { Alert } from "react-bootstrap";
import { LoginFormType } from "leeloo-react/model/authProvider/LoginFormType";
import { InputField } from "leeloo-react/component/form/field/InputField";
import { SubmitBtn } from "leeloo-react/component/form/action/SubmitBtn";

export const LoginForm = (props: LoginFormType) => {
    return (
        <div className="loginPage">
            {props.errorMsg && <Alert variant="danger">{props.errorMsg}</Alert>}
            <InputField label="Identifiant" name="username" />
            <InputField label="Mot de passe" type="password" name="password" />
            <SubmitBtn label="Connexion" />
        </div>
    );
}
